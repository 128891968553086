body, footer {
  background-color: #333;
  color: #f0f;
}

h1 {
  margin: 0;
  padding-top: 0.67em;
}

h2 {
  margin: 0;
  margin-bottom: 0.67em;
}

p {
  margin: 0 0 0.67em;
  padding: 0;
}

footer {
  border-top: 3px solid #f0f;
  text-align: right;
  padding: 10px 20px;
}

nav {
  border-bottom: 3px solid #f0f;
  text-align: center;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding: 10px 20px;
}

nav a {
  font-size: calc(8px + 2vmin);
}

nav a,
footer a {
  text-decoration: none;
  color: #f0f;
}

nav a:hover,
footer a:hover {
  color: #fff;
}

.App {
  background-color: #000;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 0.67em;
}

.App-header {
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f0f;
}

.header-message {
  margin: 20px 20px;
}

.MyButton button
{
  padding: 20px 50px;
  margin: 0;
  margin-bottom: 20px;
  font-size: calc(10px + 2vmin);
  border-radius: 10px;
  background-color: #000;
  color: #f0f;
  border: solid 5px #f0f;
}
